<template>
  <v-menu bottom left offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar color="indigo" v-bind="attrs" v-on="on">
        <v-icon dark>mdi-account-circle</v-icon>
      </v-avatar>
    </template>
    <v-list>
      <v-list-item>
        <router-link to="/profile" class="text-decoration-none">
          <a color="anchor">{{ userData.userName }}</a>
        </router-link>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item link @click="logout">
        <v-list-item-action>
          Log Out
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'AvatarMenu',

    computed: {
      ...mapGetters([
        'userData'
      ])
    },

    methods: {
      logout () {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      }
    }
  }
</script>
