<template>
  <v-app>
    <!-- <v-navigation-drawer app>
    </v-navigation-drawer> -->
    <v-app-bar v-if="(userData && userData.id) || getView().indexOf('heatmap') == -1" app color="secondary">
      <v-col cols="10">
        <v-row align="center">
          <router-link to="/" class="white--text text-decoration-none">
            <v-img max-height="64" max-width="64" src="@/assets/logo.png"></v-img>
          </router-link>
          <v-btn text to="/projects" class="ml-3 text-decoration-none white--text">Dashboard</v-btn>
          <v-btn v-if="userData && userData.isAdmin" text to="/users" class="ml-3 text-decoration-none white--text">Users</v-btn>
          <v-btn v-if="userData && userData.isAdmin" text to="/companies" class="ml-3 text-decoration-none white--text">Companies</v-btn>
          <v-btn text to="/request-new-test" class="ml-3 text-decoration-none white--text">Request New Test</v-btn>
          
        </v-row>
      </v-col>
      <v-col cols="2" align="right">
        <avatar-menu v-if="userData && userData.isAuth"/>
      </v-col>
    </v-app-bar>
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid class="ma-0 pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app class="center" color="#6FBDDB" absolute>
      <cookie-law>
        <div slot="message">
          This website uses cookies to ensure you get the best experience.{{' '}}<router-link to="/cookies" target="_blank" class="underline">Learn More</router-link>
        </div>
      </cookie-law>
      <v-row class="text-center">
        <v-col class="white--text" cols="12">
          <router-link to="/terms" target="_blank" class="mr-5 text-decoration-none white--text">Terms of Service</router-link>
          <router-link to="/cookies" target="_blank" class="mr-5 text-decoration-none white--text">Cookie Policy</router-link>
          <router-link to="/privacy" target="_blank" class="mr-5 text-decoration-none white--text">Privacy Policy</router-link>
          © DAQA 2023
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CookieLaw from 'vue-cookie-law'
  import AvatarMenu from '@/components/AvatarMenu.vue'

  export default {
    name: 'App',

    components: {
      AvatarMenu,
      CookieLaw
    },

    data () {
      return {
        logoutTimeStart: '',
      }
    },

    computed: {
      ...mapGetters([
        'userData'
      ])
    },
    
    watch: {
      userData: function () {
        // Vuetify theme
        if (this.userData) this.$vuetify.theme.dark = this.userData.isDarkMode

        // Log in: Start timer
        if (this.userData && this.userData.isAuth) {
          this.logoutTimeStart = Date.now();
          window.addEventListener('mousemove', this.updateLogoutTimer) // check timer each time mouse moves
        // Log out: Stop timer
        } else {
          this.stopLogoutTimer()
        }
      },
      deep: true
    },

    created () {
      // Vuetify theme
      if (this.userData) this.$vuetify.theme.dark = this.userData.isDarkMode
    },

    methods: {
      getView () {
        return window.location.hash
      },

      handleLogoutTimerUp () {
        this.stopLogoutTimer() // manually stop now to prevent more timers during logout process
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      },

      stopLogoutTimer () {
        window.removeEventListener('mousemove', this.updateLogoutTimer)
      },

      updateLogoutTimer () {
        // If timer is up, log out
        if (Date.now() - this.logoutTimeStart >= 1800000) {
          this.handleLogoutTimerUp()
        // Otherwise, reset timer
        } else {
          this.logoutTimeStart = Date.now();
        }
      }
    }
  }
</script>

<style lang="scss">
  .v-card {
    background-color: var(--v-card-base) !important;
  }

  .col {
    margin: 0 !important;
    padding: 0 !important;
  }

  .row {
    margin: 0 !important;
    padding: 0 !important;
  }

  .v-toolbar__content {
    margin: 0 !important;
    padding: 0 !important;
    padding-right: 7px !important;
  }
</style>