import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/account',
        name: 'Account',
        component: () =>
            import ('../views/Account.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.getters['userData'] || !store.getters['userData'].isAuth) next({ name: 'Login' })
            else if (store.getters['userData'].isPasswordResetRequired) next({ name: 'ChangePassword' })
            else next()
        }
    },
    {
        path: '/auth-management',
        name: 'AuthManagement',
        component: () =>
            import ('../views/AuthManagement.vue')
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: () =>
            import ('../views/ChangePassword.vue')
    },
    {
        path: '/change-username',
        name: 'ChangeUsername',
        component: () =>
            import ('../views/ChangeUsername.vue')
    },
    {
        path: '/companies',
        name: 'Companies',
        component: () =>
            import ('../views/Companies.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.getters['userData'] || !store.getters['userData'].isAuth) next({ name: 'Login' })
            else if (store.getters['userData'].isPasswordResetRequired) next({ name: 'ChangePassword' })
            else next()
        }
    },
    {
        path: '/cookies',
        name: 'Cookies',
        component: () =>
            import ('../views/Cookies.vue')
    },
    {
        path: '/dashboard',
        redirect: '/projects'
    },
    {
        path: '/heatmap',
        name: 'HeatmapView',
        component: () =>
            import ('../views/HeatmapView.vue')
    },
    {
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../views/Login.vue')
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () =>
            import ('../views/Privacy.vue')
    },
    {
        path: '/project',
        name: 'Project',
        props: true,
        component: () =>
            import ('../views/Project.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.getters['userData'] || !store.getters['userData'].isAuth) next({ name: 'Login' })
            else if (store.getters['userData'].isPasswordResetRequired) next({ name: 'ChangePassword' })
            else next()
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        props: true,
        component: () =>
            import ('../views/Profile.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.getters['userData'] || !store.getters['userData'].isAuth) next({ name: 'Login' })
            else if (store.getters['userData'].isPasswordResetRequired) next({ name: 'ChangePassword' })
            else next()
        }
    },
    {
        path: '/projects',
        name: 'Projects',
        component: () =>
            import ('../views/Projects.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.getters['userData'] || !store.getters['userData'].isAuth) next({ name: 'Login' })
            else if (store.getters['userData'].isPasswordResetRequired) next({ name: 'ChangePassword' })
            else next()
        }
    },
    {
        path: '/request-new-test',
        name: 'RequestNewTest',
        component: () =>
            import ('../views/RequestNewTest.vue')
    },
    {
        path: '/terms',
        name: 'Terms',
        component: () =>
            import ('../views/Terms.vue')
    },
    {
        path: '/test-view',
        redirect: '/project'
    },
    {
        path: '/tester-manage',
        name: 'TesterManage',
        props: true,
        component: () =>
            import ('../views/TesterManage.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.getters['userData'] || !store.getters['userData'].isAuth) next({ name: 'Login' })
            else if (!store.getters['userData'].isAdmin) next({ name: 'Projects' })
            else if (store.getters['userData'].isPasswordResetRequired) next({ name: 'ChangePassword' })
            else next()
        }
    },
    {
        path: '/tester-profile',
        name: 'TesterProfile',
        props: true,
        component: () =>
            import ('../views/TesterProfile.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.getters['userData'] || !store.getters['userData'].isAuth) next({ name: 'Login' })
            else if (store.getters['userData'].isPasswordResetRequired) next({ name: 'ChangePassword' })
            else next()
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: () =>
            import ('../views/Users.vue'),
        beforeEnter: (to, from, next) => {
            if (!store.getters['userData'] || !store.getters['userData'].isAuth) next({ name: 'Login' })
            else if (!store.getters['userData'].isAdmin) next({ name: 'Projects' })
            else if (store.getters['userData'].isPasswordResetRequired) next({ name: 'ChangePassword' })
            else next()
        }
    }
]

const router = new VueRouter({
    routes
})

export default router