import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import router from '@/router/index'
import { db } from '@/db'

Vue.use(Vuex)

const api = 'https://qabbb-backend.azurewebsites.net/api/'

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    userData: {}
  },
  mutations: {
    SET_USER_DATA: (state, payload) => {
      state.userData = payload
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    confirmPasswordReset({ commit }, { code, password }) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          },
          body: JSON.stringify({
            token: code,
            newPassword: password
          })
        }

        fetch(api + "auth/changePasswordWithToken", req)
        .then(() => {
          router.push('/login')
          resolve(true)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    createCompany ({ commit }, data) {
      return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + this.state.userData.token)
        
        var formdata = new FormData();
        formdata.append("name", data.name);

        // Logo
        if (data.logo) {
          formdata.append("logoFile", data.logo, data.logo.name)
        }

        // Employees
        if (data.employees) {
          for (var i = 0; i < data.employees.length; i++) {
            formdata.append("employees[" + i + "].idPerson", data.employees[i].idPerson)
            formdata.append("employees[" + i + "].idPosition", data.employees[i].idPosition)
          }
        }

        var requestOptions = {
          method: 'POST',
          headers: headers,
          body: formdata,
          redirect: 'follow'
        };
        
        fetch(api + "Company", requestOptions)
        .then((response) => {
          response.json()
          .then((result) => {
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    createEmailTemplate ({ dispatch }, templateData) {
      return new Promise((resolve, reject) => {
        // Create email template object in db
        db
        .collection('emailTemplates')
        .add(templateData)
        .then((resp) => {
          templateData.id = resp.id
          // Update template with new ID
          dispatch('updateEmailTemplate', templateData)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    createHeatmapTemplate ({ dispatch }, templateData) {
      return new Promise((resolve, reject) => {
        // Create email template object in db
        db
        .collection('heatmaps')
        .add(templateData)
        .then((resp) => {
          templateData.id = resp.id
          // Update template with new ID
          dispatch('updateHeatmapTemplate', templateData)
          .then(() => {
            resolve(templateData)
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    createProject ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + this.state.userData.token)
        
        var formdata = new FormData();
        formdata.append("idProject", data.idProject);
        formdata.append("name", data.name);
        formdata.append("startDateTime", data.startDateTime)
        formdata.append("duration", data.duration)

        // Logo
        if (data.logo) {
          formdata.append("logoFile", data.logo, data.logo.name)
        }

        // Publishers
        if (data.publishers) {
          for (i = 0; i < data.publishers.length; i++) {
            formdata.append("publishers[" + i + "].idCompany", data.publishers[i].idCompany)
          }
        }

        // Developers
        if (data.developers) {
          for (var i = 0; i < data.developers.length; i++) {
            formdata.append("developers[" + i + "].idCompany", data.developers[i].idCompany)
          }
        }

        // Platforms
        if (data.platforms) {
          for (i = 0; i < data.platforms.length; i++) {
            formdata.append("platforms[" + i + "].idPlatform", data.platforms[i].idPlatform)
            formdata.append("platforms[" + i + "].cohortSize", data.platforms[i].cohortSize)
          }
        }

        // Documents
        if (data.documents) {
          for (i = 0; i < data.documents.length; i++) {
            formdata.append("documents[" + i + "].idDocument", data.documents[i].idDocument)
            formdata.append("documents[" + i + "].idDocumentType", data.documents[i].idDocumentType)
            if (data.documents[i].idDocument == 0) {
              formdata.append("documents[" + i + "].file", data.documents[i].file, data.documents[i].file.name)
            } else {
              formdata.append("documents[" + i + "].name", data.documents[i].name)
              formdata.append("documents[" + i + "].uuid", data.documents[i].uuid)
            }
          }
        }

        // Links
        if (data.links) {
          for (i = 0; i < data.links.length; i++) {
            formdata.append("links[" + i + "].idLink", data.links[i].idLink)
            formdata.append("links[" + i + "].idLinkType", data.links[i].idLinkType)
            formdata.append("links[" + i + "].name", data.links[i].name)
            formdata.append("links[" + i + "].url", data.links[i].url)
          }
        }

        var requestOptions = {
          method: 'POST',
          headers: headers,
          body: formdata,
          redirect: 'follow'
        };
        
        fetch(api + "Project", requestOptions)
        .then((response) => {
          response.json()
          .then((result) => {
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    createTester ({ dispatch }, testerData) {
      return new Promise((resolve, reject) => {
        db
        .collection('testers')
        .add(testerData)
        .then((resp) => {
          // Update tester with new ID
          testerData.id = resp.id
          dispatch('updateTester', testerData)
          .then(() => {
            resolve(testerData.id)
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    createTesterGroup({ dispatch }, grp) {
      return new Promise((resolve, reject) => {
        // Create tester group object in db
        db
        .collection('testerGroups')
        .add(grp)
        .then((resp) => {
          grp.id = resp.id
          // Update group with new ID
          dispatch('updateTesterGroup', grp)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    createAdmin ({ commit }, id) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          },
          body: JSON.stringify({
            idPerson: id
          })
        }

        fetch(api + "Admin", req)
        .then((response) => {
          if (response.status == 201) {
            response.json()
            .then((result) => {
              resolve(result)
            })
          } else {
            response.json()
            .then((result) => {
              reject(result)
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    createUser ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          },
          body: JSON.stringify({
            personName: data.personName,
            email: data.email,
            isDarkMode: data.isDarkMode,
            roles: data.roles,
            employers: data.employers
          })
        }

        fetch(api + "User", req)
        .then((response) => {
          if (response.status == 201) {
            response.json()
            .then((result) => {
              resolve(result)
            })
          } else {
            response.json()
            .then((result) => {
              reject(result)
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    deleteEmailTemplate({ commit }, emailTemplateID) {
      return new Promise((resolve, reject) => {
        db
        .collection('emailTemplates')
        .doc(emailTemplateID)
        .delete()
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    deleteHeatmapTemplate({ commit }, templateID) {
      return new Promise((resolve, reject) => {
        db
        .collection('heatmaps')
        .doc(templateID)
        .delete()
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    deleteAdmin ({ commit }, id) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "Admin/" + id, req)
        .then((response) => {
          if (response.status == 204) {
            resolve()
          } else {
            response.json()
            .then((result) => {
              reject(result)
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getCompanies() {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "company", req)
        .then((response) => {
          if (response.ok) {
            response.json()
            .then((data) => {
              resolve(data)
            })
          }
          else reject({ code: "permission-denied"})
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getCompanyByID({ commit }, id) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "company/" + id, req)
        .then((response) => {
          if (response.ok) {
            response.json()
            .then((data) => {
              resolve(data)
            })
          }
          else if (response.status == 401) {
            console.log("resp: " + response.status)
            reject({ code: "permission-denied"})
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getCompanyEmployeePositions() {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "companyEmployeePosition", req)
        .then((response) => response.json())
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getDocumentTypes() {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "DocumentType", req)
        .then((response) => response.json())
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getEmailTemplates({ commit }) {
      return new Promise((resolve, reject) => {
        db
        .collection('emailTemplates')
        .get()
        .then((querySnapshot) => {
          let emailTemplates = querySnapshot.docs.map(doc => {
            let data = doc.data()
            let id = doc.id
            return { id, ...data }
          })
          resolve(emailTemplates)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getHeatmapData({ commit }, mapID) {
      return new Promise((resolve, reject) => {
        db
        .collection('heatmaps')
        .doc(mapID)
        .get()
        .then((resp) => {
          resolve(resp.data())
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getHeatmapTemplates() {
      return new Promise((resolve, reject) => {
        db
        .collection('heatmaps')
        .get()
        .then((querySnapshot) => {
          let heatmapTemplates = querySnapshot.docs.map(doc => {
            let data = doc.data()
            let id = doc.id
            return { id, ...data }
          })
          resolve(heatmapTemplates)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getLink({ commit }, id) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "text/html",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        const fetchURL = api + 'Test/Link/' + id
        fetch(fetchURL, req)
        .then((response) => {
          response.text()
          .then((result) => {
            console.log('got text result: ' + result)
            resolve(result)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getLinkTypes() {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "LinkType", req)
        .then((response) => response.json())
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getLogo({ commit }, uuid) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        const url = api + "File/display/" + uuid
        fetch(url, req)
        .then((data) => {
          data.blob()
          .then((data) => {
            const blobURL = URL.createObjectURL(data)
            resolve(blobURL)
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getPlatforms() {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "Platform", req)
        .then((response) => response.json())
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getProject({ commit }, id) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "project/" + id, req)
        .then((response) => response.json())
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getProjectFile({ commit }, { retrieveType, uuid }) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        const url = api + "File/" + retrieveType + "/" + uuid
        fetch(url, req)
        .then((data) => {
          data.blob()
          .then((data) => {
            const blobURL = URL.createObjectURL(data)
            resolve(blobURL)
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getProjects({ commit }) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "project", req)
        .then((response) => {
          if (response.ok) {
            response.json()
            .then((data) => {
              resolve(data)
            })
          }
          else reject({ code: "permission-denied"})
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getProjectsByCompanyID({ commit }, id) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "project/byCompany/" + id, req)
        .then((response) => response.json())
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getProjectsByUserID({ commit }, id) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "project/byUser/" + id, req)
        .then((response) => response.json())
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getPublishers() {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "company", req)
        .then((response) => response.json())
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getTesterGroups({ commit }) {
      return new Promise((resolve, reject) => {
        db
        .collection('testerGroups')
        .get()
        .then((querySnapshot) => {
          let testerGroups = querySnapshot.docs.map(doc => {
            let data = doc.data()
            let id = doc.id
            return { id, ...data }
          })
          resolve(testerGroups)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getTesterGroup({ commit }, formID) {
      return new Promise((resolve, reject) => {
        db
        .collection('testerGroups')
        .where("formID", "==", formID)
        .get()
        .then((querySnapshot) => {
          let testerGroups = querySnapshot.docs.map(doc => {
            let data = doc.data()
            let id = doc.id
            return { id, ...data }
          })
          resolve(testerGroups)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getTester({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        db
        .collection('testers')
        .doc(id)
        .get()
        .then((resp) => {
          resolve(resp.data())
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getTesters({ commit }, ids) {
      return new Promise((resolve, reject) => {
        db
        .collection('testers')
        .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
        .get()
        .then((querySnapshot) => {
          let testers = querySnapshot.docs.map(doc => {
            let data = doc.data()
            let id = doc.id
            return { id, ...data }
          })
          resolve(testers)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "text/plain",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "user/" + id, req)
        .then((response) => {
          if (response.ok) {
            response.json()
            .then((data) => {
              resolve(data)
            })
          }
          else reject({ code: "permission-denied"})
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getUserProfile({ commit }) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "user/profile", req)
        .then((response) => {
          response.json()
          .then((result) => {
            result.idUser = this.state.userData.idUser
            result.userName = this.state.userData.userName
            result.isAdmin = this.state.userData.isAdmin
            result.token = this.state.userData.token
            result.isAuth = true
            result.uid = this.state.userData.uid
            commit('SET_USER_DATA', result)
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getUsers({ commit }) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          }
        }

        fetch(api + "user", req)
        .then((response) => {
          if (response.ok) {
            response.json()
            .then((data) => {
              resolve(data)
            })
          }
          else reject({ code: "permission-denied"})
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getUsersByCompany({ commit }, companyID) {
      return new Promise((resolve, reject) => {
        db
        .collection('users')
        .where('company', '==', companyID)
        .get()
        .then((querySnapshot) => {
          let users = querySnapshot.docs.map(doc => {
            let data = doc.data()
            let id = doc.id
            return { id, ...data }
          })
          resolve(users)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getUsersByHeatmapTemplateID({ commit }, id) {
      return new Promise((resolve, reject) => {
        db
        .collection('users')
        .where('heatmapTemplates', 'array-contains', id)
        .get()
        .then((querySnapshot) => {
          let users = querySnapshot.docs.map(doc => {
            let data = doc.data()
            let id = doc.id
            return { id, ...data }
          })
          resolve(users)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    login({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            "email": email,
            "password": password
          })
        }

        fetch(api + "auth/login", req)
        .then((response) => {
          return response.ok ? response.json() : response.text()
        })
        .then((data) => {
          data.isAdmin = data.roles?.indexOf("Admin") > -1
          data.isAuth = true
          data.uid = data.idUser
          commit('SET_USER_DATA', data)
          router.push('/dashboard')
          resolve(true)
        })
        .catch((error) => {
          //error = "Invalid email or password"
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    logout({ commit }) {
      return new Promise((resolve) => {
        if (this.state.userData && this.state.userData.isAuth) {
          commit('SET_USER_DATA', { userData: null })
          router.push('/login')
          resolve(true)
        }
      })
    },

    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit }, email) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          },
          body: JSON.stringify({
            email: email
          })
        }

        fetch(api + "auth/requestNewPassword", req)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    searchTesters ({ commit }, { params }) {
      return new Promise((resolve, reject) => {
        db
        .collection('testers')
        .get()
        .then((querySnapshot) => {
          // Loop through each result to apply filters, until we find a better way to do this on back-end
          var testers = []
          querySnapshot.forEach((doc) => {
            var tester = Object.assign({}, doc.data())
            tester.id = doc.id

            // Apply filters
            var isFilteredOut = false

            // Basic Information 
            if (params !== undefined  ) {
              if ((params.discordID && (!tester['DiscordID'] || !tester['DiscordID'].toLowerCase().includes(params.discordID.toLowerCase()))) ||
              (params.firstName && (!tester['FName'] || !tester['FName'].toLowerCase().includes(params.firstName.toLowerCase()))) ||
              (params.lastName && (!tester['LName'] || !tester['LName'].toLowerCase().includes(params.lastName.toLowerCase()))) ||
              (params.region && (!tester['Region'] || tester['Region'] !== params.region)) ||
              (params.country && (!tester['Country'] || tester['Country'] !== params.country)) ||
              (params.ageRange && (!tester['AgeRange'] || tester['AgeRange'] !== params.ageRange)) ||
              (params.gender && (!tester['Gender'] || tester['Gender'] !== params.gender)) ||
              (params.platform && (!tester['Platforms'] || tester['Platforms'].indexOf(params.platform) == -1)) ||
              (params.downloadSpeed && (!tester['DownloadSpeed'] || tester['DownloadSpeed'] !== params.downloadSpeed)) ||
              (params.uploadSpeed && (!tester['UploadSpeed'] || tester['UploadSpeed'] !== params.uploadSpeed))) {
                isFilteredOut = true
              }
            }

            if (!isFilteredOut) testers.push(tester)
          })
          
          resolve(testers)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    updateCompany({ commit }, data) {
      return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + this.state.userData.token)
        
        var formdata = new FormData();
        formdata.append("idCompany", data.idCompany);
        formdata.append("name", data.name);

        // Logo
        if (data.logo) {
          formdata.append("logoFile", data.logo, data.logo.name)
        }

        // Employees
        if (data.employees) {
          for (var i = 0; i < data.employees.length; i++) {
            formdata.append("employees[" + i + "].idPerson", data.employees[i].idPerson)
            formdata.append("employees[" + i + "].idPosition", data.employees[i].idPosition)
          }
        }

        var requestOptions = {
          method: 'PUT',
          headers: headers,
          body: formdata,
          redirect: 'follow'
        };
        
        fetch(api + "company", requestOptions)
        .then((response) => {
          response.json()
          .then((result) => {
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    updateEmailTemplate({ dispatch }, emailTemplate) {
      return new Promise((resolve, reject) => {
        db
        .collection('emailTemplates')
        .doc(emailTemplate.id)
        .update(emailTemplate)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    updateHeatmapTemplate({ dispatch }, templateData) {
      // Search template for new files to upload
      var promises = []
      if (templateData.layers && templateData.layers.length > 0) {
        var layersToUpload = []
        var layer
        for (var i = 0; i < templateData.layers.length; i++) {
          layer = templateData.layers[i]
          if (layer.imageFile) {
            layersToUpload.push(layer)
            promises.push(
              dispatch('uploadFile', { file: layer.imageFile, path: 'heatmaps/' + templateData.id + '/' + layer.imageFile.name })
            )
            delete layer.imageFile // can't update document w/ actual file reference
          }
        }
      }

      return new Promise((resolve, reject) => {
        db
        .collection('heatmaps')
        .doc(templateData.id)
        .update(templateData)
        .then(() => {
          // Upload files and logo
          if (promises.length > 0) {
            Promise.all(promises)
            .then((urls) => {
              // Update template with newly generated file URLs
              for (var i = 0; i < layersToUpload.length; i++) {
                layersToUpload[i].imagePath = urls[i]
              }
              dispatch('updateHeatmapTemplate', templateData)
              .then(() => {
                resolve()
              })
              .catch((error) => {
                reject(error)
              })
            })
            .catch((error) => {
              reject(error)
            })
          } else {
            resolve()
          }
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    updatePassword({ commit, dispatch }, { newPassword }) {
      return new Promise((resolve, reject) => {
        firebase
        .auth()
        .currentUser
        .updatePassword(newPassword)
        .then(() => {
          // Update user data with password reset flag
          commit('SET_USER_DATA', { ...this.state.userData, isPasswordResetRequired: false })
          dispatch('updateUserData', { id: this.state.userData.id })
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
          resolve()
        }).catch((error) => {
          if (error.message.includes('requires recent authentication')) {
            dispatch('logout')
            .then(() => {
              router.push({ name: 'Login', params: { isRecentAuthError: true } })
            })
          }
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    updateProject({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Authorization", "Bearer " + this.state.userData.token)
        
        var formdata = new FormData();
        formdata.append("idProject", data.idProject);
        formdata.append("name", data.name);
        formdata.append("startDateTime", data.startDateTime)
        formdata.append("duration", data.duration)

        // Logo
        if (data.logo) {
          formdata.append("logoFile", data.logo, data.logo.name)
        }

        // Publishers
        if (data.publishers) {
          for (i = 0; i < data.publishers.length; i++) {
            formdata.append("publishers[" + i + "].idCompany", data.publishers[i].idCompany)
          }
        }

        // Developers
        if (data.developers) {
          for (var i = 0; i < data.developers.length; i++) {
            formdata.append("developers[" + i + "].idCompany", data.developers[i].idCompany)
          }
        }

        // Platforms
        if (data.platforms) {
          for (i = 0; i < data.platforms.length; i++) {
            formdata.append("platforms[" + i + "].idPlatform", data.platforms[i].idPlatform)
            formdata.append("platforms[" + i + "].cohortSize", data.platforms[i].cohortSize)
          }
        }

        // Documents
        if (data.documents) {
          for (i = 0; i < data.documents.length; i++) {
            formdata.append("documents[" + i + "].idDocument", data.documents[i].idDocument)
            formdata.append("documents[" + i + "].idDocumentType", data.documents[i].idDocumentType)
            if (data.documents[i].idDocument == 0) {
              formdata.append("documents[" + i + "].file", data.documents[i].file, data.documents[i].file.name)
            } else {
              formdata.append("documents[" + i + "].name", data.documents[i].name)
              formdata.append("documents[" + i + "].uuid", data.documents[i].uuid)
            }
          }
        }

        // Links
        if (data.links) {
          for (i = 0; i < data.links.length; i++) {
            formdata.append("links[" + i + "].idLink", data.links[i].idLink)
            formdata.append("links[" + i + "].idLinkType", data.links[i].idLinkType)
            formdata.append("links[" + i + "].name", data.links[i].name)
            formdata.append("links[" + i + "].url", data.links[i].url)
          }
        }

        var requestOptions = {
          method: 'PUT',
          headers: headers,
          body: formdata,
          redirect: 'follow'
        };
        
        fetch(api + "Project", requestOptions)
        .then((response) => {
          response.json()
          .then((result) => {
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    updateUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          },
          body: JSON.stringify({
            idPerson: data.idPerson,
            personName: data.personName,
            isDarkMode: data.isDarkMode,
            status: "Active",
            employers: data.employers
          })
        }

        fetch(api + "User", req)
        .then((response) => {
          response.json()
          .then((result) => {
            resolve(result)
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    updateUserProfile({ commit }, data) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + this.state.userData.token
          },
          body: JSON.stringify({
            idPerson: data.idPerson,
            personName: data.personName,
            isDarkMode: data.isDarkMode,
            status: "Active",
            employers: data.employers
          })
        }

        fetch(api + "User/profile", req)
        .then((response) => {
          if (response.status == 204) {
            resolve(data)
          } else reject(response.status)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    verifyPasswordResetCode({ commit, dispatch }, code) {
      return new Promise((resolve, reject) => {
        const req = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: code
          })
        }

        fetch(api + "auth/checkPasswordRequestToken", req)
        .then((resp) => {
          if (resp.status == 200) {
            dispatch('logout')
            resolve()
          }
          else reject('Error Code ' + resp.status)
        })
        .catch((error) => {
          reject(error)
        })
      })
    }
  },
  modules: {},
  getters: {
    userData: state => state.userData,
  }
})