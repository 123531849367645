import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueGtag from 'vue-gtag'
import VueScrollTo from 'vue-scrollto'

Vue.config.productionTip = false

Vue.use(
  VueGtag, { config: { id: 'UA-233487605-1'} }
)
Vue.use(
  VueScrollTo, {
    container: 'body',
    duration: 500,
    easing: 'ease',
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
  }
)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')