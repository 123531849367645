import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: "#000000",
                secondary: "#2B3C4F",
                card: colors.grey.lighten4,
                card2: colors.grey.lighten2,
                accent: colors.pink.darken1,
                error: colors.red.accent3,
                background: colors.white,
                info: colors.black,
                primaryButton: "#6FBDDB"
            },
            dark: {
                primary: "#FFFFFF",
                secondary: "#2B3C4F",
                card: colors.grey.darken3,
                card2: colors.grey.darken4,
                background: colors.blue,
                info: colors.white,
                anchor: colors.white,
                primaryButton: "#6FBDDB"
            }
        }
    }
});
